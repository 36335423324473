import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { finalize, skip } from 'rxjs/operators';
import { SnackbarStatus } from 'src/definitions/snackbar-status.enum';
import { LoginOAuth } from 'src/models/user';
import { AuthService } from 'src/shared-components/ng-login/auth/auth.service';
import { LoadingStore } from 'src/stores/loading.store';

import { CustomSnackbarService } from './custom-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {
  /**
   * Providers custom options for each sso login
   */
  private loginOptions;

  /**
   * Auth state subscription
   */
  private loginSubscription: Subscription | undefined;

  /**
   * The subject signaling that the sso login data has been loaded
   */
  loginResponse = new Subject<boolean>();

  /**
   * The observable signaling that the sso login data has been loaded
   */
  loginResponse$: Observable<boolean> = this.loginResponse.asObservable();

  /**
   * To make sure we do not try to log in with the info authState had previously
   */
  previousOAuthData: SocialUser | null = null;

  constructor(
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private loading: LoadingStore,
    private snackbarService: CustomSnackbarService,
    private translateService: TranslateService
  ) {
    /* eslint-disable @typescript-eslint/naming-convention */
    this.loginOptions = {
      [GoogleLoginProvider.PROVIDER_ID]: {
        redirect_uri: window.location.origin
      }
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  handleAuthState(redirectUrl: string): void {
    this.loginSubscription = this.socialAuthService.authState.subscribe((user) => {
      if (user !== null && user !== this.previousOAuthData) {
        const redirectUri = this.loginOptions[user.provider]?.redirect_uri || '';
        const oauthParemeters = user.authorizationCode
          ? new LoginOAuth('', user.authorizationCode, redirectUri)
          : new LoginOAuth(user.authToken, '', redirectUri);

        if (redirectUrl !== '') {
          const params = new HttpParams({
            fromObject: {
              oauthData: JSON.stringify(oauthParemeters),
              provider: user.provider.toLowerCase()
            }
          });

          window.location.href = `${redirectUrl}/login?${params.toString()}`;
        } else {
          this.login(oauthParemeters, user.provider.toLowerCase()).subscribe((loginOk) => {
            this.unsubscribe();
            this.previousOAuthData = user;
            this.loginResponse.next(loginOk);
          });
        }
      }
    });
  }

  /**
   * Sign in method to login in selected SSO provider
   * @param providerId - SSO provider id
   * @param redirectUrl - Only used if SSO does not support custom origin url scheme
   */
  signIn(providerId: string, redirectUrl = ''): void {
    this.socialAuthService
      .signIn(providerId, this.loginOptions[providerId])
      .then(() => {
        this.handleAuthState(redirectUrl);
      })
      .catch((error) => {
        this.unsubscribe();
        this.handleOAuthError(error);
      });
  }

  /**
   * Method to log in our server with selected provider and configuration
   * @param oauthParameters
   * @param provider - SSO provider name in lowercase
   */
  login(oauthParameters: LoginOAuth, provider: string): Observable<boolean> {
    return this.authService.loginSSO(oauthParameters, provider).pipe(
      skip(1),
      finalize(() => this.unsubscribe())
    );
  }

  private handleOAuthError(error: string): void {
    this.loading.stop();

    if (error !== 'User cancelled login or did not fully authorize.') {
      this.snackbarService.present(
        this.translateService.instant('DefaultErrors.Something-wrong'),
        SnackbarStatus.Error
      );
    }
  }

  unsubscribe(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
      this.loginSubscription = undefined;
    }

    this.resetSocialLogin();
  }

  /**
   * Method to reset current selected SSO on failure
   */
  resetSocialLogin(): void {
    this.loginResponse.next(false);
    this.loading.stop();
  }
}
